.manage-submission-container {
  position: relative;
  width: 90%;
  max-width: 1050px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 120px;
  padding-bottom: 120px;
  border-radius: 4px;
  min-height: 800px;

  h1 {
    margin: 0;
    margin-bottom: 60px;
    font-size: 48px;
  }

  h3 {
    margin-top: 60px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  input {
    max-width: 70%;
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 16px;
    height: 22px;
  }


  p {
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 15px;
    line-height: 140%;
  }

  .save-button {
    margin-bottom: 20px;
    margin-left: 10px;
    height: 40px;
  }


  ul.competition-submission-list {
    list-style-type: none;
    margin: 0;
    margin-bottom: 30px;
    padding: 0;
    width: 90%;
    max-width: 600px;
    padding: 20px;
    padding-top: 0px;

    li {
      width: 100%;
      height: 60px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
    }

    li:hover {
      border: 1px solid #465bd6;
    }

    .submission-status {
      position: relative;
      border: 1px solid #666;
      background-color: #fff;
      padding: 5px 10px;
      text-align: center;
      font-size: 12px;
      border-radius: 4px;
      font-weight: 500;
      margin-left: auto;
      right: 0px;
  
      cursor: pointer;
    }

  }

}